{
  const cards = document.querySelectorAll('.card__link');
  const drawer = document.getElementById('hinid-drawer');

  if(drawer) {
    // Add click event listener to all cards
    Array.prototype.forEach.call(cards, (card) => {
      card.addEventListener('click', cardClickHandler);
    });

    // Add click handler to "back" buton and to grey area
    drawer.addEventListener('click', closeHandler);

    // Add ESC keydown handler
    window.addEventListener('keydown', drawerEscHandler);
  }

  function drawerEscHandler(e) {
    if(e.keyCode === 27) {
      const drawer = document.getElementById('hinid-drawer');
      document.documentElement.classList.remove('drawer-opened');
      drawer.classList.remove('active');
      drawer.innerHTML = '';
    }
  }

  function backClickHandler() {
    const drawer = document.getElementById('hinid-drawer');
    document.documentElement.classList.remove('drawer-opened');
    drawer.classList.remove('active');
    drawer.innerHTML = '';
  }

  function closeHandler(e) {
    if(e.target.classList.contains('hinid-drawer__close-js') || e.target.id === 'hinid-drawer') {
      const drawer = document.getElementById('hinid-drawer');
      document.documentElement.classList.remove('drawer-opened');
      drawer.classList.remove('active');
      drawer.innerHTML = '';
    }
  }

  function cardClickHandler(e) {
    e.preventDefault();

    const reqUrl = this.dataset.href;

    var req = new XMLHttpRequest();
    req.addEventListener("load", reqListener);
    req.open("GET", reqUrl);
    req.send();
  }

  function reqListener() {
    const drawer = document.getElementById('hinid-drawer');
    document.documentElement.classList.add('drawer-opened');
    drawer.classList.add('active');
    drawer.innerHTML = this.responseText

    // Init all double sliders
    var doubleSliders = $('.double-slider');
  	if(doubleSliders.length) { // if any exists
  		doubleSliders.each(function(index, sliderWrapper) {
  			runDoubleSlider($(sliderWrapper).attr('id'));
  		});
  	}

    // Make all tables responsive
    if (document.querySelectorAll('table').length) { // if any exists
    	tableResponsive.init();
    }

    // Init all accordions
    var $accordion = $('.accordion');
    if ($accordion.length) { // if any exists
      $accordion.on('click', '.accordion__link', handleAccordionClick);
    }
  }
}
