(function($){

    $('.global-menu__link.expandable').on('click', function(event) {
        if(hasSubMenu(this) && isLabelVisible(this)) {
            event.preventDefault();
            var triggerInput = $(this).next('.submenu').find('input.menu-lvl2__trigger');
            !triggerInput.prop('checked')?
                triggerInput.prop('checked', true).trigger('change')
                :triggerInput.prop('checked', false).trigger('change');
        }
    });

    function hasSubMenu(el) {
    	return $(el).next('.submenu').find('label.menu-lvl2__trigger-label').length;
    }
    function isLabelVisible(el) {
		return $(el).next('.submenu').find('label.menu-lvl2__trigger-label').css('display') == 'none'? false: true;
    }


}(jQuery));