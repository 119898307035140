(function($) {
	
	// Handle loader
	var loader = function (status) {
		if (status) {
			$('#loader').show();
			$('#loader').attr('aria-busy', true);
		} else {
			$('#loader').hide();
			$('#loader').attr('aria-busy', false);
		}
	}

	// Handle popup
	var openLoginPopup = function (loginBoxHTML) {

		// Create login popup
		$('#login-popup-box').html(loginBoxHTML);

		// Show login popup
		$('#login-popup-box').show();

		// Hide loader
		loader(false);
	};

	// Clear popup
	var clearPopup = function() {
		$('#login-popup-box').html('');
	}

	// Hide popup
	var hidePopup = function(status) {
		if (status) {
			$('#login-popup-box').hide();
		} else {
			$('#login-popup-box').show();
		}
	}

	// Check is page accessible 200 VS 403
	var checkLogin = function (pageUrl) {

		// Show loader
		loader(true);

		$.ajax({
			url: pageUrl
		}).done(function (data, textStatus, xhr) {

			// Hide loader
			loader(false);

			// Redirect
			window.location.href = pageUrl;

		}).fail(function (jqXHR, textStatus, errorThrown) {
			var loginBoxHTML = $('<div />').html(jqXHR.responseText).find('#login-wrapper').html();
			openLoginPopup(loginBoxHTML);
		});

	};

	// Trigger on .checkLogin click
	$('body').on('click', '.checkLogin', function (e) {
		e.preventDefault();
		if (!$('#login-wrapper').length) {
			checkLogin($(this).attr('href'));
		}
	});


	// Hide popup on close click
	$('body').on('click', '.login-popup__close', function(e) {
		e.preventDefault();
		if (!$('#login-wrapper').length) {
			$(this).parents('.login-popup__wrapper').hide();
			clearPopup();
		}
	});

	// Hide popup on wrapper click
	$('body').on('click', '.login-popup__wrapper', function(e) {
		if ($(e.target).hasClass('login-popup__wrapper') && !$('#login-wrapper').length) {
			$(this).hide();
			clearPopup();
		}
	});

	// Hide popup on ESC key
	$(window).on('keydown', function(e) {
		if (e.keyCode == 27 && $('#login-popup').is(':visible') && !$('#login-wrapper').length) {
			$('.login-popup__wrapper').hide();
			clearPopup();
		}
	});

	// Popup login
	$('body').on('submit', '#login-popup-box #login_form_login_prompt', function (e) {
		e.preventDefault();

		var form = $('#login-popup-box #login_form_login_prompt');

		// Hide popup
		hidePopup(true);

		// Show loader
		loader(true);

		$.ajax({
			method: 'post',
			url: form.attr('action'),
			data: form.serialize()
		}).done(function (data) {
			window.location.href = form.attr('action')
		}).fail(function (jqXHR, textStatus, errorThrown) {
			
			// Refresh login box on error
			var loginBoxHTML = $('<div />').html(jqXHR.responseText).find('#login-wrapper').html();
			openLoginPopup(loginBoxHTML);
			
		});
	})

})(jQuery);
