$(document).ready(function() {


	function validateEmail(email) {
	    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    return re.test(email);
	}

	// FORM VALIDATION
    if ($('form').length > 0) {
        var inputs = $('input');
        var textareas = $('textarea');
        var msgName = 'Please enter your name.';
        var msgEmail = 'Please enter your email address.';
        // Validate inputs
        if (inputs.length > 0) {
            inputs.each(function (index, input) {
                if ($(input).attr('type') === 'email') {
                    $(input).on('focusout', function () {
                        var parent = $(this).parents('.sq-form-question');
                        if (!validateEmail($(this).val())) {
                            if (!parent.children('.form__alert--invalid').length) {
                                parent.append('<span class="form__alert--invalid">'+ msgEmail +'</span>');
                            }
                            parent.addClass('invalid');
                        } else {
                            if (parent.children('.form__alert--invalid').length > 0) {
                                parent.children('.form__alert--invalid').remove();
                            }
                            parent.removeClass('invalid');
                        }
                    });
                } else if ($(input).attr('required')) {
                    $(input).on('focusout', function () {
                        var parent = $(this).parents('.sq-form-question');
                        if ($(this).val().length < 1) {
                            if (!parent.children('.form__alert--invalid').length) {
                            	parent.append('<span class="form__alert--invalid">'+ msgName +'</span>');
                        	}
                            parent.addClass('invalid');
                        } else {
                            if (parent.children('.form__alert--invalid').length > 0) {
                            	parent.children('.form__alert--invalid').remove();
                        	}
                            parent.removeClass('invalid');
                        }
                    });
                }
            });
        }
		// Validate textareas
        if (textareas.length > 0) {
            textareas.each(function (index, textarea) {
                var currVal;
                $(textarea).parents('.sq-form-question-answer').append('<p class="form__char-counter"><span class="char-count">0</span> / 350</p>');
                $(textarea).on('keydown', function (e) {
                    currVal = $(this).val();
                });
                $(textarea).on('input', function () {
                    if ($(this).val().length > 349) {
                        $(this).parents('.sq-form-question').addClass('limit');
                        // Add "Reached limit" text
                        if (!$(this).next('.form__char-counter').children('.limit-info').length) {
                            $(this).next('.form__char-counter').prepend('<span class="limit-info">Reached character limit </span>');
                        }
                        if ($(this).val().length > 350) {
                            $(this).val(currVal);
                        }
                    } else {
                        $(this).parents('.sq-form-question').removeClass('limit');
                        // Remove "Reached limit" text
                        if ($(this).next('.form__char-counter').children('.limit-info').length > 0) {
                            $(this).next('.form__char-counter').children('.limit-info').remove();
                        }
                    }
                    $(this).next('.form__char-counter').children('.char-count').text($(this).val().length);
                });
            });
        }
        // File input
        if ($('input[type="file"]').length > 0) {
            $('input[type="file"]').on('change', function () {
                $(this).parents('.sq-form-question').children('.form__file-path').html($(this).val());
            });
        }
    } //  /FORM VALIDATION
});

var tableResponsive = {
	tabels: document.querySelectorAll('table'),
	counter: 0,
	resizeDebauncer: undefined,

	init: function() {
		var _this = this;
		this.tabels = document.querySelectorAll('table');

		Array.prototype.forEach.call(this.tabels, function(el) {
			_this.addWraper(el);
			el.style.width = '100%';
		});

		_this.checkWrapperOverflow();
		_this.checkWrapperOverflowOnResize();
	},
	checkWrapperOverflow: function() {
		var tableWrapper = document.querySelectorAll('.table__wrapper');
		for (var i = 0; i < tableWrapper.length; i += 1) {
			var infoDivId = tableWrapper[i].getAttribute('rel');

			if (tableWrapper[i].scrollWidth > tableWrapper[i].clientWidth) {
				document.getElementById(infoDivId).style.display = 'block';
			} else {
				document.getElementById(infoDivId).style.display = 'none';
			}
		}
	},

	checkWrapperOverflowOnResize: function() {
		var _this = this;

		window.onresize = function (event) {
			if (undefined !== _this.resizeDebauncer) {
				clearTimeout(_this.resizeDebauncer);
			}
			_this.resizeDebauncer = setTimeout(function(){
				_this.checkWrapperOverflow();
			}, 50);
		}
	},

	addWraper: function(table) {
		var wrapper = document.createElement('div'),
			prepend = document.createElement('div');

		wrapper.classList.add('table__wrapper');

		prepend.classList.add('table__wrapper--scrollinfo');
		prepend.innerText = '← You can scroll this table horizontally →';
		prepend.id = 'table_' + this.counter;;

		table.parentNode.insertBefore(wrapper, table);
		wrapper.appendChild(table);
		wrapper.setAttribute('rel', 'table_' + this.counter);

		prepend = wrapper.parentNode.insertBefore(prepend, wrapper);

		this.counter = this.counter+=1;
	}
}

if (document.querySelectorAll('table').length) {
	tableResponsive.init();
}
