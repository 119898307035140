{
  if (document.getElementById('interactive-diagram')) {
    let tabsObj = {
      sectionWrapper: document.getElementById('interactive-diagram'),
      tabs: document.querySelectorAll('.interactive__tab-js'),
      tabContents: document.querySelectorAll('.hindid-tab-content-js'),
      tabsWrapper: document.querySelector('.interactive__tabs'),
      tabsOffset: document.querySelector('.interactive__tabs') ? document.querySelector('.interactive__tabs').offsetTop : 0
    };

    window.addEventListener('scroll', scrollHandler.bind(tabsObj));
    window.addEventListener('hashchange', hashchangeHandler);
    document.addEventListener('DOMContentLoaded', loadDrawerHandler);
    // Prevent from jumping to ID if its current
    Array.prototype.forEach.call(tabsObj.tabs, tab => tab.addEventListener('click', preventHashJump));
  }

  function loadDrawerHandler(e) {
    let currHash = window.location.hash.length > 0 ? window.location.hash.replace('#', '') : false;

    if (currHash && document.getElementById(currHash)) {
      window.location.hash = '';

      let currTab = document.querySelector('.interactive__tabs').querySelector("a[href='#" + currHash + "']");
      currTab.click();
    } else {
      let firstTab = document.querySelector('.interactive__tabs').querySelector('.interactive__tab-js');

      if (document.getElementById(firstTab.dataset.targetContent)) {
        firstTab.click();
      }
    }
  }

  function scrollHandler(e) {
    let top = document.documentElement.scrollTop + 72;
    let currentMobileTitle = document.querySelector('.hindid-tab-content.active .interactive__tab-title--mobile');

    if (top - this.tabsOffset > 0) {
      this.tabsWrapper.classList.add('sticky');
      if (currentMobileTitle) {
        currentMobileTitle.classList.add('sticky');
      }
      // if (window.innerWidth < 600) {
      //   this.sectionWrapper.style.paddingTop = currentMobileTitle.offsetHeight + this.tabsWrapper.offsetHeight + 'px';
      // } else {
      //   this.sectionWrapper.style.paddingTop = this.tabsWrapper.offsetHeight + 'px';
      // }
    } else {
      if (currentMobileTitle) {
        currentMobileTitle.classList.remove('sticky');
      }
      this.tabsWrapper.classList.remove('sticky');
      this.sectionWrapper.style.paddingTop = '0';
    }
  }

  function hashchangeHandler(e) {
    let newId = window.location.hash.replace('#', '');
    let isAnyTabActive = document.querySelector('.interactive__tab.active');
    if (document.getElementById(newId)) {
      // Remove .active from all tabs and all contents and add 'hidden' attribute to all contents
      deactivateTabs();
      hideSections();
      // Show desired content
      document.getElementById(newId).classList.add('active');
      document.getElementById(newId).removeAttribute('hidden');
      // Add .active to current tab
      let newCurrTab = document.querySelector("a[href='#" + newId + "']").parentNode;
      newCurrTab.classList.add('active');
      newCurrTab.setAttribute('aria-selected', true);

      if (isAnyTabActive) {
        $('html, body').animate(
          {
            scrollTop: $('#interactive-diagram').offset().top - 72
          },
          500
        );
      }

      // Return false to prevent jumping to section with given id
      return false;
    }
  }

  function preventHashJump(e) {
    if (
      !this.classList.contains('interactive__tab--second-level') &&
      document.getElementById(this.dataset.targetContent.replace('#', '')).classList.contains('active')
    ) {
      e.preventDefault();
    }
  }

  function deactivateTabs() {
    let tabs = document.querySelectorAll('.interactive__tab-js');
    // Remove .active from all tabs
    Array.prototype.forEach.call(tabs, tab => {
      tab.parentNode.classList.remove('active');
      tab.parentNode.setAttribute('aria-selected', false);
    });
  }

  function hideSections() {
    let tabContents = document.querySelectorAll('.hindid-tab-content-js');
    // Remove .active and add 'hidden' attribute from all tab contents
    Array.prototype.forEach.call(tabContents, tabContent => {
      tabContent.classList.remove('active');
      tabContent.setAttribute('hidden', true);
    });
  }
}

window.onload = () => {
  // Handle id anchors scrolling
  const anchors = document.querySelectorAll(".anchor__list a[href^='#']");
  const interactiveContainer = document.querySelector('#interactive-diagram');
  const headerContainer = document.querySelector('.header');
  const mobileTitle = document.querySelector('.hindid-tab-content.active .interactive__tab-title--mobile');
  console.log(mobileTitle);
  if (anchors.length > 0 && interactiveContainer) {
    [...anchors].map(anchor =>
      anchor.addEventListener('click', event => {
        event.preventDefault();
        const name = anchor.getAttribute('href').substring(1);
        const destination = document.querySelector(`[name='${name}']`);
        // 16px is heigh of interactive diagram angle
        if (mobileTitle) {
          window.scrollTo(
            0,
            destination.offsetTop - interactiveContainer.offsetHeight - headerContainer.offsetHeight - mobileTitle.offsetHeight - 16
          );
        } else {
          window.scrollTo(0, destination.offsetTop - interactiveContainer.offsetHeight - headerContainer.offsetHeight - 16);
        }
      })
    );
  }
};
