{
  // Last section "Next: xXx" click listener
  document.addEventListener('click', (e) => {
    if(e.target.classList.contains('hindid_next-js')) {
      e.preventDefault();
      let nextTab = document.querySelector('.interactive__tab.active').nextElementSibling.querySelector('.interactive__tab-js');
      if(nextTab) {
        nextTab.click();
      }
    }
  });
}
