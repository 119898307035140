function isPublic() {
	var response = this.response;
	if(!response.publicUser) {
		var footerRequest = new XMLHttpRequest();
		footerRequest.addEventListener("load", function() {
			var $footer = $('#logout_section');
			$footer.prepend(this.response);
		});
		footerRequest.open("GET", footerLogout);
		footerRequest.send();
	}
}

(function() {
	var $footerLogoutWrapper = $('.logout-wrapper');
	if(!$footerLogoutWrapper.length) {
		var oReq = new XMLHttpRequest();
		oReq.addEventListener("load", isPublic);
		oReq.open("GET", authCheckGateway);
		oReq.responseType = "json";
		oReq.send();
	}
})();