var $accordion = $('.accordion');

function handleAccordionClick (e) {
  var accItem = $(this).parent();
  var accTarget = $(this).next();

  $(this).toggleClass('accordion__link--active');
  accItem.attr('aria-expanded', accItem.attr('aria-expanded') === 'true' ? false : true);
  accTarget.slideToggle('fast').attr('aria-hidden', accTarget.attr('aria-hidden') === 'true' ? false : true);

  e.preventDefault();
}

if ($accordion.length) {
  $accordion.on('click', '.accordion__link', handleAccordionClick);
}
