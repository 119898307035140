(function(){
  'use strict';

  var matchMedia = window.matchMedia ? true : false;
  var largeBreakpoint;
  var $header = $('header.header');
  var $globalMenuList = $('ul.global-menu__list');
  var $globalMenuLinks = $('a.global-menu__link');
  var $globalMenuItems = $('li.global-menu__item');
  var $globalMenuInputs = $('input.submenu__trigger');
  var keys = {
    tab: 9
  };

  if (matchMedia) {
    largeBreakpoint = window.matchMedia('(min-width: 1248px)');
    largeBreakpoint.addListener(breakpointHandler);
  }

  $(document).scroll(function () {
    $(document).scrollTop() ? $header.addClass('scrolled') : $header.removeClass('scrolled');
  });

  //MENU KEYBOARD ACCESSIBILITY
  $(document).keyup(function (event) {
    if (event.keyCode === keys.tab && $(':focus').hasClass('menu-lvl2__link')) {
	    $(':focus').parents('.global-menu__item').children('.global-menu__link').addClass('focused');
    } else if (event.keyCode === keys.tab) {
      $('.global-menu__link').removeClass('focused');
    }
  });

  //HINR-30 QA: focus trap fixed
  $globalMenuList.on('mouseleave, focusout', function () {
    $globalMenuLinks.each(function () {
      $(this).removeClass('focused');
    });
  });

  //HINR-30 QA: aria {expanded | hidden} toggle
  function toggleAria (elem) {
    $(elem).children().each(function () {
      $(this).attr('aria-expanded') 
        ? $(this).attr('aria-expanded', $(this).attr('aria-expanded') === 'true' ? false : true)
        : $(this).attr('aria-hidden') 
          ? $(this).attr('aria-hidden', $(this).attr('aria-hidden') === 'true' ? false : true)
          : null;
    });
  }

  function uncheckInputs () {
    $globalMenuInputs.each(function () {
      if ($(this).prop('checked')) {
        $(this).prop('checked', false);
        $(this).trigger('change');
      }
    });
  }

  function toggleEventListeners (flag) {
    if (flag) {
      $globalMenuItems.on('mouseenter', function () {
        $(this).addClass('mouseenter');

        if (!$(this).hasClass('focusin')) {
          toggleAria($(this));
        }
      });
      $globalMenuItems.on('mouseleave', function () {
        $(this).removeClass('mouseenter');

        if (!$(this).hasClass('focusin')) {
          toggleAria($(this));
        }
      });
      $globalMenuItems.on('focusin', function () {
        $(this).addClass('focusin');

        if (!$(this).hasClass('mouseenter')) {
          toggleAria($(this));
        }
      });
      $globalMenuItems.on('focusout', function () {
        $(this).removeClass('focusin');

        if (!$(this).hasClass('mouseenter')) {
          toggleAria($(this));
        }
      });
      $globalMenuItems.off('change');
    } else {
      $globalMenuItems.off('mouseenter');
      $globalMenuItems.off('mouseleave');
      $globalMenuItems.off('focusin');
      $globalMenuItems.off('focusout');
      $globalMenuItems.on('change', function (e) {
        toggleAria($(e.target).closest('.submenu__item'));
      });
      $globalMenuItems.on('change', '.menu-lvl2__trigger', function (e) {
        toggleAria($(e.target).parents('.global-menu__item'));
      });
    }
  }

  function breakpointHandler () {
    uncheckInputs();
    toggleAria($('.selected').parents('.global-menu__item'));
    toggleAria($('.selected').parents('.submenu__item'));
    largeBreakpoint.matches ? toggleEventListeners(true) : toggleEventListeners(false);
  }

  window.addEventListener('DOMContentLoaded', breakpointHandler);

}());
