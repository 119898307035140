if($('.slider').length > 0 ) {
    $('.slider').slick({
        dots:       true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 600,
                settings:   {
                    arrows: false
                }
            }
        ]
    });
}
