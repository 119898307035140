var runDoubleSlider = function (id) {

	moveSlideDescriptions(id);


	if($('#'+id).find('.double-slider__screen').length) {
		var slides = $('#'+id).find('.double-slider__screen-slide');
		if(slides.length < 5) {
			$('#'+id).addClass('less-than-5');
		}
		var screenSlider = $('#'+id).find('.double-slider__screen').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			asNavFor: '#' + id + ' .double-slider__thumbs',
		});
	}

	if($('#'+id).find('.double-slider__thumbs').length) {
		var thumbsSlider = $('#'+id).find('.double-slider__thumbs').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			asNavFor: '#' + id + ' .double-slider__screen',
			arrows: false,
			focusOnSelect: true,
			variableWidth: true,
			responsive: [
				{
					breakpoint: 960,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1,
						asNavFor: '#' + id + ' .double-slider__screen',
						arrows: false,
						focusOnSelect: true,
					}
				}
			]
		});
	}

	var last;

	$('#'+id).find('.double-slider__screen').on('beforeChange', function(event, slick, currentSlide, nextSlide){
		last = currentSlide;
		$(this).next('.double-slider__footer').children('.double-slider__description').children('div[data-slick-desc-index]').css({opacity: 0});
	});
	$('#'+id).find('.double-slider__screen').on('afterChange', function(event, slick, currentSlide){
		$(this).next('.double-slider__footer').children('.double-slider__description').children('[data-slick-desc-index="' + last + '"]').css({display: 'none'});
		$(this).next('.double-slider__footer').children('.double-slider__description').children('div[data-slick-desc-index="' + currentSlide + '"]').css({display: 'block', opacity: 1});
	});
};

var moveSlideDescriptions = function(id) {
	var descriptions = $('#'+id).find('div[data-slick-desc-index]');
	if(descriptions.length) {
		descriptions.each(function(index, value) {
			$('#'+id+' .double-slider__description').append($(value));
		});
	}
}

var doubleSliders = $('.double-slider');

if(doubleSliders.length) {

	doubleSliders.each(function(index, sliderWrapper) {
		runDoubleSlider($(sliderWrapper).attr('id'));
	});

}

var iframeHeight = {
	$slider: $('.double-slider__screen'),
	$ytIframe: $('.yt-iframe'),

	init: function() {
		var _this =this;

		this.$slider.each(function(index, slider) {
			$(slider).find(_this.$ytIframe).css({
				height: $(slider).height(),
				position: 'relative',
				display: 'block'
			});
		});
	}
};

$(window).on('load', function() {
      iframeHeight.init();
  });

  var iframeHeightDebauncer;
  $(window).on('resize', function(){
      if (undefined !== iframeHeightDebauncer) {
          clearTimeout(iframeHeightDebauncer);
      }
      iframeHeightDebauncer = setTimeout(function(){
          iframeHeight.init();
      }, 50);
  });
